.h-wrapper {
  background-color: var(--black);
  color: white;
}
.h-container {
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.h-menu {
  gap: 2rem;
}
.h-menu a {
  text-decoration: none;
  color: white;
}

.h-menu > *:hover {
  cursor: pointer;
  color: var(--secondary);
}

.menu-icon {
  display: none;
}
.h-menuMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .h-menuMobile {
    color: var(--black);
    position: absolute;
    top: 4rem;
    right: 2rem;
    background: white;
    flex-direction: column;
    font-weight: 500;
    gap: 1.5rem;
    padding: 3rem;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.08);
    z-index: 999;
    display: flex;
  }

  .h-menuMobile a {
    margin: 5px 0;
    color: black;
    text-decoration: none;
  }

  .h-container {
    flex-direction: column;
  }
  .menu-icon {
    display: block;
    position: absolute;
    right: 1rem;
  }
  .h-menu {
    display: none;
  }
}
