.r-container {
  overflow: hidden;
  position: relative;
}
.r-head {
  margin-bottom: 2rem;
}
.r-card {
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  margin: auto;
  transition: all 300ms ease-in;
}
.r-card > img {
  width: 100%;
  max-width: 15rem;
}
.r-card > :nth-child(2) {
  font-size: 1.2rem;
  font-weight: 600;
}
.r-card > :nth-child(3) {
  font-size: 1.5rem;
}
.r-card > :nth-child(4) {
  font-size: 0.7rem;
  width: 15rem;
}
.r-card:hover {
  scale: 1.025;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(136, 160, 255, 0.46) 217.91%
  );
  box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}
.swiper-horizontal {
  overflow: visible;
}
.r-button {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 25px;
}
.r-button > button {
  width: 60px;
  height: 30px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: orange;
  margin-top: 10px;
  transition: all 300ms ease-out;
}
.r-button > button:hover {
  scale: 1.1;
}
@media screen and (max-width: 768px) {
  .r-wrapper {
    margin-bottom: 3rem;
  }
  .r-container {
    padding: 0 1rem;
  }

  .r-card {
    max-width: 100%;
    width: 100%;
  }

  .r-card > :nth-child(3) {
    font-size: 1.2rem;
  }

  .r-card > :nth-child(4) {
    font-size: 0.6rem;
    width: auto;
  }
  .r-button {
    justify-content: center;
  }
  .r-button > button {
    width: 15%;
  }
}
