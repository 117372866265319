.hero-wrapper {
  color: white;
  position: relative;
  padding-bottom: 2rem;
  background-color: var(--black);
}
.hero-container {
  justify-content: space-between;
  align-items: flex-end;
}
.hero-left {
  gap: 3rem;
}
.hero-title {
  position: relative;
  z-index: 1;
}
.hero-title > h1 {
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 4rem;
}
.orange-circle {
  height: 4rem;
  width: 4rem;
  background-color: orange;
  border-radius: 999px;
  position: absolute;
  right: 30%;
  top: -10%;
  z-index: -1;
}
.search-bar {
  border-radius: 5px;
  background-color: white;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: 3px solid rgba(120, 120, 120, 0.374);
  width: 100%;
}
.search-bar > span {
  font-size: 1.2rem;
  color: #0a58ca;
}
.search-bar > input {
  outline: none;
  border: none;
  width: 70%;
}
.stats {
  width: 100%;
  justify-content: space-between;
}
.stat > :nth-child(1) {
  font-size: 1.5rem;
}
.stat > :nth-child(1) .icon {
  color: orange;
}
.image-container {
  width: 30rem;
  height: 30rem;
  overflow: hidden;
  border-radius: 15rem 15rem 0 0;
  border: 8px solid rgba(255, 255, 255, 0.12);
}
.image-container > img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    align-items: center;
  }

  .hero-left {
    gap: 2rem;
    text-align: center;
  }

  .hero-title > h1 {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  .orange-circle {
    right: 50%;
    top: -15%;
  }

  .search-bar {
    width: 100%;
  }

  .stats {
    flex-direction: row;
    align-items: center;
  }

  .stat {
    text-align: center;
    margin: 0.5rem 0;
  }

  .image-container {
    width: 20rem;
    height: 20rem;
    border-radius: 10rem 10rem 0 0;
  }
}
